import { isPlatformServer } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Inject, NgModule, PLATFORM_ID, isDevMode } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from '@app/core/interceptors/auth.interceptor';
import { ErrorHandlerInterceptor } from '@app/core/interceptors/error-handler.interceptor';
// import { environment } from '@env/environment';
import { NgxSsrCacheModule } from '@ngx-ssr/cache';
import { LazyLoadImageModule } from 'ng-lazyload-image';
// import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxSsrCacheModule.configLruCache({ maxAge: 5 * 60_000, maxSize: 5 }),
    LazyLoadImageModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    GraphQLModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // RecaptchaV3Module,
  ],
  providers: [
    provideClientHydration(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
    ) {
    const domain = (isPlatformServer(platformId)) ? 'http://localhost:4000/' : '';
    const customIconsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${domain}assets/icons/custom-icons.svg`);
    const iconlyIconsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${domain}assets/icons/iconly-icons.svg`);
    const saxIconsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${domain}assets/icons/sax-icons.svg`);
    const socialIconsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${domain}assets/icons/social-icons.svg`);

    this.matIconRegistry.addSvgIconSetInNamespace('custom', customIconsUrl);
    this.matIconRegistry.addSvgIconSetInNamespace('iconly', iconlyIconsUrl);
    this.matIconRegistry.addSvgIconSetInNamespace('sax', saxIconsUrl);
    this.matIconRegistry.addSvgIconSetInNamespace('social', socialIconsUrl);
  }
}
