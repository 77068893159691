import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { main } from '@popperjs/core';
import { Apollo, gql } from 'apollo-angular';
import { filter, map, Observable, of, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(private apollo: Apollo) { }

  checkEmailAvailability(email: string): Observable<boolean> {
    const query = gql`
      query checkEmailAvailability($email: String!) {
        isEmailUnique(email: $email)
      }
    `;

    return this.apollo.watchQuery({ query, variables: { email }})
      .valueChanges
      .pipe(
        take(1),
        map((result: ApolloQueryResult<{ isEmailUnique: boolean }>) => result.data?.isEmailUnique),
      );
  }

  checkUsernameAvailability(username: string): Observable<boolean> {
    const query = gql`
      query checkUsernameAvailability($username: String!) {
        isUsernameUnique(username: $username)
      }
    `;

    return this.apollo.watchQuery({ query, variables: { username }})
      .valueChanges
      .pipe(
        take(1),
        map((result: ApolloQueryResult<{ isUsernameUnique: boolean }>) => result.data?.isUsernameUnique),
      );
  }
}
