import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationService } from '@shared/services/validation.service';
import { map, Observable } from 'rxjs';
import { isValidPhoneNumber } from 'libphonenumber-js'

export class EvlandValidators {
  static passwordComplexity(control: AbstractControl): ValidationErrors | null {
    const passwordRe = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/;
    return passwordRe.test(control.value) ? null : { weakPassword: { value: control.value } };
  }

  static matchPassword(passwordFieldName: string, confirmPasswordFieldName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const passwordControl = control.get(passwordFieldName);
      const passwordValue = passwordControl.value;
      const confirmPasswordControl = control.get(confirmPasswordFieldName);
      const confirmPasswordValue = confirmPasswordControl.value;

      confirmPasswordControl.setErrors(
        (passwordValue || confirmPasswordValue) && passwordValue !== confirmPasswordValue
          ? { passwordMismatch: { control: passwordFieldName } }
          : null
      );

      return null;
    };
  }

  static phoneNumber(control: AbstractControl): ValidationErrors | null {
    return !control.value || isValidPhoneNumber(control.value, 'UA') ? null : { phoneNumber: true };
  }

  static url(control: AbstractControl): ValidationErrors | null {
    const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;
    return !control.value || regex.test(`${ control.value }`) ? null : { url: true };
  }

  static minFloat(min: number): ValidatorFn {
    min = min ?? 0;

    return (c: AbstractControl): ValidationErrors | null => {
      const floatValue = customParseFloat(c.value);

      if (floatValue >= min) {
        return null;
      }

      return { min: { valid: false, min } };
    };
  }

  static maxFloat(max: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      const floatValue = customParseFloat(c.value);

      if (floatValue <= max) {
        return null;
      }

      return { max: { valid: false, max } };
    };
  }

  static minLengthArray(min: number): ValidatorFn {
    min = min ?? 0;

    return (c: AbstractControl): ValidationErrors | null => {
      if (!c.value || c.value.length >= min) {
        return null;
      }

      return { minLengthArray: { valid: false, min } };
    };
  }

  static maxLengthArray(max: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (!c.value || c.value.length <= max) {
        return null;
      }

      return { maxLengthArray: { valid: false, max } };
    };
  }

  static percentageValidator(control: AbstractControl): ValidationErrors | null {
    const { value: _value } = control;
    const value = customParseFloat(_value);

    if (value < 1) {
      return { minPercentage: { valid: false } };
    } else if (value > 100) {
      return { maxPercentage: { valid: false } };
    } else {
      return null;
    }
  }

  static validateEmailAvailability(validationService: ValidationService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return validationService
        .checkEmailAvailability(control.value)
        .pipe(
          map(result => result ? {} : { server: $localize `Користувач із цією електронною поштою вже існує.` })
        );
    };
  }

  static validateUsernameAvailability(validationService: ValidationService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return validationService
        .checkUsernameAvailability(control.value)
        .pipe(
          map(result => result ? {} : { server: $localize `Це ім'я користувача недоступне.` })
        );
    };
  }
}

function customParseFloat(value: string): number {
  let validValue = `${ (value || 0).toString().replace(',', '.') }`;

  if (validValue.endsWith('.')) {
    validValue = `${ validValue }0`;
  }

  return parseFloat(validValue);
}
