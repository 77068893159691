import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CallbackType } from '@shared/models/callback-type';
import { NavAction } from '@shared/models/nav-action';
import { ActionLink, NavLink, NavLinkWithIcon } from '@shared/models/nav-link';
import { CallbackStoreService } from '@shared/stores/callback-store.service';

@Component({
  selector: 'evland-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  contentLinkGroups: { label: string, links: (ActionLink | NavLink)[] }[] = [
    {
      label: $localize `Користувач`,
      links: [
        { action: NavAction.Auth, label: $localize `Реєстрація/Вхід`, disabled: true },
        { href: '/account', label: $localize `Особистий кабінет`, disabled: true },
        { href: '/comparison', label: $localize `Порівняння` },
      ],
    },
    {
      label: $localize `Навігація`,
      links: [
        { href: '/', label: $localize `Головна` },
        { href: '/catalog', label: $localize `Каталог електромобілів` },
        { href: '/offers', label: $localize `Пропозиції з пробігом` },
        { href: '/about-us', label: $localize`Про компанію` },
        { href: '/contact-us', label: $localize`Контакти` },
      ],
    },
    // {
    //   label: $localize `Електромобілі`,
    //   links: [
    //     { href: '/warehouse', label: $localize `На складі`, disabled: true },
    //     { href: '/catalog', label: $localize `Каталог електромобілів` },
    //     { href: '/offers', label: $localize `Актуальні пропозиції` },
    //   ],
    // },
  ];
  importantNavLinks: NavLink[] = [
    { href: '/terms-and-conditions', label: $localize `Правила користування сайтом` },
    { href: '/terms-and-conditions', fragment: 'privacyPolicy', label: $localize `Політика конфіденційності` },
    { href: '/public-offer', label: $localize `Публічна оферта` },
  ];
  socialNavLinks: NavLinkWithIcon[] = [
    { href: 'https://www.facebook.com/groups/1211464519478659/', label: $localize`Ми у Facebook`, icon: 'social:facebook' },
    { href: 'https://www.instagram.com/chargespy', label: $localize`Ми у Instagram`, icon: 'social:instagram' },
    { href: 'https://t.me/chademoforchina', label: $localize`Наш Telegram-чат`, icon: 'social:telegram' },
    { href: 'https://www.youtube.com/@chargespy', label: $localize`Ми на YouTube`, icon: 'social:youtube' },
  ];
  year: number = (new Date()).getFullYear();

  constructor(private callbackStore: CallbackStoreService) {}

  onActionClick(action: NavAction): void {
  }

  openCallbackModal(): void {
    this.callbackStore.show({
      type: CallbackType.Consultation,
      title: $localize `Замовити консультацію`,
      actionLabel: $localize`Замовити`,
      subject: $localize`Консультація`,
    });
  }
}
