import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BaseStoreService } from '@shared/stores/base-store.service';

const LOCAL_STORAGE_KEY = 'comparison_list';

@Injectable({
  providedIn: 'root'
})
export class ComparisonStoreService extends BaseStoreService<string> {
  private isServer: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: string) {
    super();

    this.isServer = isPlatformServer(this.platformId);

    if (this.isServer) return;

    this.list = localStorage.getItem(LOCAL_STORAGE_KEY) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) : [];
  }

  addId(id: string, ignoreSave?: boolean): void {
    if (this.list.includes(id)) return;

    this.addToList(id);

    if (this.isServer || ignoreSave) return;

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.list));
  }

  addIds(ids: string[]): void {
    ids.forEach((id: string) => this.addId(id, true));

    if (this.isServer) return;

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.list));
  }

  deleteId(id: string, ignoreSave?: boolean): void {
    if (!this.list.includes(id)) return;

    this.list = this.list.filter((i: string) => i !== id);

    if (this.isServer || ignoreSave) return;

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.list));
  }

  deleteIds(ids: string[]): void {
    ids.forEach((id: string) => this.deleteId(id, true));

    if (this.isServer) return;

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.list));
  }

  toggleId(id: string, ignoreSave?: boolean): void {
    const isInList = this.list.includes(id);

    isInList ? this.list = this.list.filter((i: string) => i !== id) : this.addToList(id);

    if (this.isServer || ignoreSave) return;

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.list));
  }

  toggleIds(ids: string[]): void {
    ids.forEach((id: string) => this.toggleId(id, true));

    if (this.isServer) return;

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.list));
  }
}
