import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'evland-footer-chats',
  templateUrl: './footer-chats.component.html',
  styleUrls: ['./footer-chats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterChatsComponent {

}
