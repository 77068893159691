import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/core/shared/auth.service';
import { JwtService } from '@app/core/shared/jwt.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Auth } from '@shared/graphql/generated';
import { ToastType } from '@shared/models/toast-type';
import { ToastStoreService } from '@shared/stores/toast-store.service';
import { finalize, switchAll } from 'rxjs';

@Component({
  selector: 'evland-sign-in-modal',
  templateUrl: './sign-in-modal.component.html',
  styleUrls: [ './sign-in-modal.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInModalComponent {
  form: UntypedFormGroup;
  isSubmitting: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private jwtService: JwtService,
    private modalService: NgbModal,
    private toastStore: ToastStoreService,
  ) {
    this.createForm();
  }

  onClose(): void {
    this.activeModal.close();
  }

  onForgotPasswordClick(): void {
    this.activeModal.dismiss('forgotPassword');
  }

  onSignUpClick(): void {
    this.activeModal.dismiss('signUp');
  }

  onSubmit(): void {
    if (this.form.invalid || this.form.pristine || this.isSubmitting) return;

    this.isSubmitting = true;

    const { email, password, rememberMe } = this.form.value;

    this.authService.signIn(email, password, rememberMe)
      .pipe(
        finalize(() => {
          this.isSubmitting = false;

          this.cdr.markForCheck();
        }),
      )
      .subscribe(() => {
        this.toastStore.show({
          text: $localize`Ви успішно авторизувалися`,
          type: ToastType.Success,
        });

        this.activeModal.close();
      }, err => {
        this.toastStore.show({
          text: $localize`Користувач не знайдено або ви вказали неправильний пароль`,
          type: ToastType.Error,
        });
      });
  }

  private createForm(): void {
    this.form = this.fb.group({
      email: [ '', [ Validators.required, Validators.email ] ],
      password: [ '', Validators.required ],
      rememberMe: false,
    });
  }
}
