import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { SEARCH_QUERY } from '@shared/consts/graphql';
import { Brand, Series } from '@shared/graphql/generated';
import { Apollo } from 'apollo-angular';
import { debounceTime, map, of, Subject, switchMap, takeUntil, tap } from 'rxjs';

interface SearchResults {
  brands: Brand[];
  series: Series[];
}

@Component({
  selector: 'evland-header-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarComponent implements OnDestroy {
  @ViewChild(NgbDropdown) dropdown: any;

  form: UntypedFormGroup;
  searchResults: SearchResults;
  showSearch: boolean;
  isSearching: boolean;

  private readonly destroyed$ = new Subject<void>();

  get queryControl(): UntypedFormControl {
    return this.form.get('query') as UntypedFormControl;
  }

  constructor(
    private apollo: Apollo,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private router: Router,
  ) {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  clearSearch(): void {
    this.searchResults = null;
    this.showSearch = false;

    this.form.reset({}, { emitEvent: false });
    this.cdr.markForCheck();
  }

  onInputFocus(): void {
    if (this.isSearching || !this.showSearch) return;

    this.dropdown.open();
  }

  onSubmit(): void {
    this.router.navigate(['/search'], { queryParams: { query: this.queryControl.value } });
    this.clearSearch();
  }

  private createForm(): void {
    this.form = this.fb.group({
      query: [ '', [ Validators.required ] ],
    });

    this.queryControl.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        tap(() => {
          this.isSearching = true;

          this.cdr.markForCheck();
        }),
        debounceTime(400),
        switchMap((query: string) => this.queryControl.valid ? this.apollo.watchQuery({
          query: SEARCH_QUERY,
          variables: { query, take: 3 }
        }).valueChanges : of(null)),
        map((result: ApolloQueryResult<{ searchBrands: Brand[], searchSeries: Series[] }>) => {
          if (!result) return [[], []];

          const { searchBrands, searchSeries } = result.data;
          const filteredBrands = searchBrands.filter((brand: Brand) => !!brand.series_count);

          return [ filteredBrands, searchSeries ];
        }),
      )
      .subscribe(([ brands, series ]: [ Brand[], Series[] ]) => {
        this.searchResults = { brands: brands, series };
        this.isSearching = false;
        this.showSearch = true;

        this.dropdown.open();
        this.cdr.markForCheck();
      });
  }
}
