import { animate, style, transition, trigger } from '@angular/animations';
import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AfterContentInit, booleanAttribute,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, ContentChild, ContentChildren, ElementRef, HostBinding, Input,
  OnDestroy,
  OnInit, QueryList,
  ViewEncapsulation
} from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { ValidationMessageService } from '@shared/services/validation-message.service';
import { FormFieldAbstractControl } from '@shared/ui-components/form-field/shared/form-field-abstract-control';
import { InputDirective } from '@shared/ui-components/form-field/shared/input.directive';
import { startCase } from 'lodash-es';
import { merge, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'evland-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('errorInOut', [
      transition(':enter', [
        style({ height: 0 }),
        animate('100ms', style({ height: '*' })),
      ]),
      transition(':leave', animate('100ms', style({ opacity: '0', height: '0' })))
    ]),
  ]
})
export class FormFieldComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() label: string;
  @ContentChild(InputDirective) input: InputDirective;
  @HostBinding('class') class = 'evland-form-field';
  @HostBinding('class.focused') focused: boolean;
  control: NgControl;

  // need to think about it, using last control as main control of the field
  @ContentChildren(NgControl) set controls(v: QueryList<NgControl>) {
    this.control = v.last;
  }

  @HostBinding('class.invalid') get invalid() {
    return this.control?.invalid;
  }

  @HostBinding('class.touched') get touched() {
    return this.control?.touched;
  }

  @Input({ transform: booleanAttribute })
  @HostBinding('class.disabled') get disabled() {
    return this.control?.disabled || this.input?.disabled;
  }

  get isRequired(): boolean {
    return this.control?.control.hasValidator(Validators.required);
  }

  get message(): string | void {
    if (!this.control?.errors) {
      return;
    }

    const friendlyControlName = startCase(this.control.name);
    const key = Object.keys(this.control.errors)[0];
    const error = this.control.getError(key);

    return this.messageService.getMessage(key, friendlyControlName, error);
  }

  private readonly destroyed$ = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private messageService: ValidationMessageService,
    private fm: FocusMonitor,
    private element: ElementRef,
  ) {
    this.fm.monitor(this.element, true)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(origin => {
        this.focused = !!origin && this.control?.enabled;
        this.cdr.markForCheck();
      });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngAfterContentInit(): void {
    let changes$ = [];

    if (this.control) {
      changes$ = [ ...changes$, this.control.valueChanges, this.control.statusChanges ];

      // // todo add common interface for state changes
      // if (this.control.valueAccessor instanceof TelInputComponent
      //   || this.control.valueAccessor instanceof QuillControlComponent
      //   || this.control.valueAccessor instanceof MultiselectComponent
      // ) {
      //   changes$ = [ ...changes$, this.control.valueAccessor.stateChanged$ ];
      // }

      if (this.control.valueAccessor instanceof FormFieldAbstractControl) {
        changes$ = [ ...changes$, this.control.valueAccessor.stateChanged$ ];
      }
    }

    if (this.input) {
      changes$ = [ ...changes$, this.input.stateChanged$ ];
    }

    merge(...changes$)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(() => this.cdr.markForCheck());
  }
}
