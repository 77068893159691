import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { CallbackData } from '@shared/models/callback-data';

@Injectable({
  providedIn: 'root'
})
export class FormCallbackService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  submit(data: any): any {
    return this.httpClient.post(`${environment.apiUrl}/webhook/form-callback`, {
      ...data,
      url: window.location.href,
    });
  }
}
