import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Toast } from '@shared/models/toast';
import { ToastStoreService } from '@shared/stores/toast-store.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'evland-toasts-area',
  templateUrl: './toasts-area.component.html',
  styleUrls: ['./toasts-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastsAreaComponent implements OnDestroy {
  toasts$: Observable<Toast[]>;

  constructor(private toastStore: ToastStoreService) {
    this.toasts$ = this.toastStore.list$;
  }

  ngOnDestroy(): void {
    this.toastStore.clear();
  }

  removeToast(toast: Toast): void {
    this.toastStore.removeFromList(toast.id);
  }

  trackByFn(index: number, toast: Toast): string {
    return toast.id;
  }
}
