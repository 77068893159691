<form *ngIf="data" (ngSubmit)="onSubmit()" [formGroup]="form">

  <evland-modal-container (onClose)="onClose()" [title]="data.title" [withFooter]="true">

    <evland-form-field label="Ваше ім'я" i18n-label>
      <input evlandInput ngbAutofocus formControlName="name" class="form-control" id="name" />
    </evland-form-field>

    <evland-form-field label="Номер телефону" i18n-label>
      <input [imask]="{ mask: '+{38\\0} (00) 000-00-00' }" evlandInput formControlName="contactNumber" class="form-control" placeholder="+380 (XX) XXX-XX-XX" />
    </evland-form-field>

    <ng-container modal-footer>
      <evland-submit-button [isLoading]="isSubmitting" class="submit-button">{{data.actionLabel}}</evland-submit-button>
    </ng-container>

  </evland-modal-container>

</form>
