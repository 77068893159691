<form (ngSubmit)="onSubmit()" [formGroup]="form">

  <evland-modal-container (onClose)="onClose()" title="Відновлення паролю" i18n-title>

    <div class="mb-3">

      <div class="mb-3">

        <label for="email" class="form-label" i18n>E-Mail</label>
        <input ngbAutofocus formControlName="email" class="form-control" id="email" />

      </div>

      <evland-submit-button [isLoading]="isSubmitting" class="d-block mb-3" i18n>Скинути пароль</evland-submit-button>

      <a (click)="onSignInClick()" class="btn btn-lg btn-outline-primary d-block w-100" i18n>Увійти</a>

    </div>

  </evland-modal-container>

</form>
