
<div class="modal-header">
  <h2 class="modal-title">{{title}}</h2>
  <button type="button" class="close-button" (click)="onCloseClick()">
    <mat-icon class="icon" svgIcon="custom:close"></mat-icon>
  </button>
</div>

<div [class.with-footer]="withFooter" class="modal-body">
  <ng-content></ng-content>
</div>

<div *ngIf="withFooter" class="modal-footer">
  <ng-content select="[modal-footer]"></ng-content>
</div>
