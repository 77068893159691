import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[evlandInput]'
})
export class InputDirective {
  stateChanged$ = new Subject<void>();

  get htmlEl(): HTMLInputElement {
    return this.el.nativeElement;
  }

  get type(): string {
    return this.htmlEl.type;
  }

  get disabled(): boolean {
    return this.htmlEl.disabled;
  }

  constructor(
    private el: ElementRef<HTMLInputElement>,
  ) { }

  @HostListener('blur')
  onInput(): void {
    this.stateChanged$.next();
  }

  toggleVisibility(): void {
    this.htmlEl.type = this.type === 'password' ? 'text' : 'password';
  }
}
