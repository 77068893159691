import { Injectable } from '@angular/core';
import { startCase } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class ValidationMessageService {
  private messages = {
    required: () => $localize`Поле обов'язкове.`,

    email: () => $localize`Будь ласка, введіть дійсну адресу електронної пошти.`,

    min: (error) => $localize`Поле повинно бути принаймні ${error.min}.`,

    max: (error) => $localize`Поле не може бути більше ніж ${error.max}.`,

    maxlength: (error) =>
      $localize`Поле не може перевищувати ${error.requiredLength} символів.`,

    minlength: (error) =>
      $localize`Поле має містити принаймні ${error.requiredLength} символів.`,

    passwordMismatch: (error) => $localize`Паролі не співпадають.`,

    url: () => $localize`Поле має бути дійсною URL-адресою.`,


    weakPassword: () => $localize`Поле має містити як мінімум 1 велику та 1 нижню літери, 1 цифру, 1 символ (!@#$%^&*/), та довжину від 8 до 64 символів.`,

    phoneNumber: () => $localize`Недійсний номер, перевірте та введіть ще раз.`,

    server: (error) => error,
  };

  constructor() { }

  getMessage(errorName: string, fieldName: string, error: any): string {
    return this.messages[ errorName ](error);
  }
}
