import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { JwtService } from '@app/core/shared/jwt.service';
import { Auth, User } from '@shared/graphql/generated';
import { UserStoreService } from '@shared/stores/user-store.service';
import { Apollo } from 'apollo-angular';
import { map, Observable, switchMap, take, tap } from 'rxjs';
import { GET_ME, SIGN_IN } from './graphql';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private apollo: Apollo,
    private jwtService: JwtService,
    private userStore: UserStoreService,
  ) {
  }

  signIn(email: string, password: string, remember: boolean): Observable<User> {
    const input = {
      email,
      password,
    };

    return this.apollo.mutate({
      mutation: SIGN_IN,
      variables: { input }
    })
      .pipe(
        take(1),
        map((result: ApolloQueryResult<{ signIn: Auth }>) => result.data && result.data?.signIn),
        tap((auth: Auth) => this.jwtService.saveToken(auth.access_token)),
        switchMap(() => this.me()),
      );
  }

  me(): Observable<User> {
    return this.apollo.watchQuery({ query: GET_ME })
      .valueChanges
      .pipe(
        take(1),
        map((result: ApolloQueryResult<{ me: User }>) => result.data?.me),
        tap((user: User) => this.userStore.current = user),
      );
  }

  logout(): void {
    this.jwtService.destroyToken();

    this.userStore.current = null;
  }
}
