import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ToastType } from '@shared/models/toast-type';
import { ToastStoreService } from '@shared/stores/toast-store.service';
import { Observable, tap } from 'rxjs';

const ERROR_STATUSES: number[] = [ 401, 404, 500 ];

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(private toastStore: ToastStoreService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        tap((resp: HttpResponse<any>) => {
          if (!resp.url?.startsWith(environment.apiUrl) || !resp.body.errors?.length) return;

          const { errors } = resp.body;

          if (errors[0].extensions.code === 'UNAUTHENTICATED') this.toastStore.show({ text: $localize`Потрібна авторизація`, type: ToastType.Error });
        }),
      );
  }
}
