import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbToastModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CallbackModalModule } from '@shared/modals/callback-modal/callback-modal.module';
import { ForgotPasswordModalModule } from '@shared/modals/forgot-password-modal/forgot-password-modal.module';
import { SignInModalModule } from '@shared/modals/sign-in-modal/sign-in-modal.module';
import { SignUpModalModule } from '@shared/modals/sign-up-modal/sign-up-modal.module';
import { LoaderModule } from '@shared/ui-components/loader/loader.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidenavMenuComponent } from './sidenav-menu/sidenav-menu.component';
import { SearchBarComponent } from './header/search-bar/search-bar.component';
import { ToastsAreaComponent } from './toasts-area/toasts-area.component';
import { CookieConfirmationComponent } from './cookie-confirmation/cookie-confirmation.component';
import { FullPageLoaderComponent } from './full-page-loader/full-page-loader.component';
import { FooterChatsComponent } from './footer-chats/footer-chats.component';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidenavMenuComponent,
    SearchBarComponent,
    ToastsAreaComponent,
    CookieConfirmationComponent,
    FullPageLoaderComponent,
    FooterChatsComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ToastsAreaComponent,
    CookieConfirmationComponent,
    FullPageLoaderComponent,
    FooterChatsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatIconModule,
    NgbTooltipModule,
    NgbDropdownModule,
    LazyLoadImageModule,
    NgbToastModule,
    CallbackModalModule,
    SignInModalModule,
    SignUpModalModule,
    ForgotPasswordModalModule,
    LoaderModule,
  ]
})
export class CoreModule { }
