import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormFieldModule } from '@shared/ui-components/form-field/form-field.module';
import { ModalContainerModule } from '@shared/ui-components/modal-container/modal-container.module';
import { SubmitButtonModule } from '@shared/ui-components/submit-button/submit-button.module';
import { IMaskModule } from 'angular-imask';
import { CallbackModalComponent } from './callback-modal.component';



@NgModule({
  declarations: [
    CallbackModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SubmitButtonModule,
    IMaskModule,
    ModalContainerModule,
    FormFieldModule
  ]
})
export class CallbackModalModule { }
