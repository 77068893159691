import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CallbackModalComponent } from '@shared/modals/callback-modal/callback-modal.component';
import { CallbackData } from '@shared/models/callback-data';
import { BaseStoreService } from '@shared/stores/base-store.service';

@Injectable({
  providedIn: 'root'
})
export class CallbackStoreService extends BaseStoreService<CallbackData> {

  constructor(
    private ngbModal: NgbModal,
  ) {
    super();
  }

  show(data: CallbackData): void {
    this.current = data;

    this.ngbModal.open(CallbackModalComponent);
  }
}
