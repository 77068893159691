import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SignInModalModule } from '@shared/modals/sign-in-modal/sign-in-modal.module';
import { ModalContainerModule } from '@shared/ui-components/modal-container/modal-container.module';
import { SubmitButtonModule } from '@shared/ui-components/submit-button/submit-button.module';
import { ForgotPasswordModalComponent } from './forgot-password-modal.component';



@NgModule({
  declarations: [
    ForgotPasswordModalComponent
  ],
  imports: [
    CommonModule,
    ModalContainerModule,
    MatIconModule,
    ReactiveFormsModule,
    SubmitButtonModule,
  ]
})
export class ForgotPasswordModalModule { }

