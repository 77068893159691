import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { environment } from '@env/environment';
import { JwtService } from '../shared/jwt.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly jwtService: JwtService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.jwtService.getToken();

    if (token && request.url.startsWith(environment.apiUrl)) {
      const update: any = token ? { setHeaders: { Authorization: `Bearer ${token}` } } : {};

      request = request.clone(update);
    }

    return next.handle(request);
  }
}
