<evland-header></evland-header>

<evland-toasts-area></evland-toasts-area>

<router-outlet></router-outlet>

<evland-footer-chats></evland-footer-chats>

<evland-footer></evland-footer>

<evland-cookie-confirmation></evland-cookie-confirmation>
