import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CallbackData } from '@shared/models/callback-data';
import { ToastType } from '@shared/models/toast-type';
import { FormCallbackService } from '@shared/services/form-callback.service';
import { CallbackStoreService } from '@shared/stores/callback-store.service';
import { ToastStoreService } from '@shared/stores/toast-store.service';
import { EvlandValidators } from '@shared/utils/validators';
// import { ReCaptchaV3Service } from 'ng-recaptcha';
import { finalize, Subject, takeUntil } from 'rxjs';

declare var gtag;
declare var fbq;

const GTAG_SHOW_EVENT_NAME = 'callback_modal';

@Component({
  selector: 'evland-callback-modal',
  templateUrl: './callback-modal.component.html',
  styleUrls: [ './callback-modal.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallbackModalComponent implements OnInit, OnDestroy {
  data: CallbackData;
  form: UntypedFormGroup;
  isSubmitting: boolean;

  private readonly destroyed$ = new Subject<void>();

  constructor(
    private activeModal: NgbActiveModal,
    private callbackStore: CallbackStoreService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private formCallbackService: FormCallbackService,
    // private recaptchaV3Service: ReCaptchaV3Service,
    private toastStore: ToastStoreService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.initData();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onClose(): void {
    this.activeModal.dismiss();
  }

  onSubmit(): void {
    if (this.form.invalid || this.form.pristine || this.isSubmitting) return;

    this.isSubmitting = true;

    const { name, contactNumber } = this.form.value;
    const { subject, brandId, seriesId, modelId, offerId, color, subColor } = this.data;
    // const action = brandId || seriesId || modelId || offerId || color || subColor ? 'OrderFormSubmit' : 'ConsultationFormSubmit';

    // this.recaptchaV3Service.execute(action)
    this.formCallbackService.submit({
      subject,
      name,
      contact_number: contactNumber,
      ...(brandId && { brand_id: brandId }),
      ...(seriesId && { series_id: seriesId }),
      ...(modelId && { model_id: modelId }),
      ...(offerId && { offer_id: offerId }),
      ...(color && { color }),
      ...(subColor && { sub_color: subColor }),
    })
      .pipe(
        // switchMap((token: string) => this.formCallbackService.submit({
        //   subject,
        //   name,
        //   contact_number: contactNumber,
        //   ...(brandId && { brand_id: brandId }),
        //   ...(seriesId && { series_id: seriesId }),
        //   ...(modelId && { model_id: modelId }),
        //   ...(offerId && { offer_id: offerId }),
        //   ...(color && { color }),
        //   ...(subColor && { sub_color: subColor }),
        // })),
        finalize(() => {
          this.isSubmitting = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe(
        () => {
          const values = [
            ...(offerId ? [ `offer_${offerId}` ] : []),
            ...(modelId ? [ `model_${modelId}` ] : []),
            ...(seriesId ? [ `series_${seriesId}` ] : []),
            ...(brandId ? [ `brand_${brandId}` ] : []),
          ];

          try {
            gtag('event', this.data.type, {
              'value': !!values.length ? values.join(',') : 'none',
            });
          } catch (e) {
            console.error('gtag not found');
          }

          try {
            fbq('track', 'Lead');
          } catch (e) {
            console.error('fbq not found');
          }

          this.toastStore.show({
            text: $localize`Заявку успішно надіслано. Наші менеджери незабаром зв'яжуться з вами`,
            type: ToastType.Success
          });
          this.form.reset();
          this.activeModal.close();
        },
        () => this.toastStore.show({
          text: $localize`Помилка! Перевірте ще раз форму або спробуйте пізніше`,
          type: ToastType.Error
        }),
      );
  }

  private createForm(): void {
    this.form = this.fb.group({
      name: [ '', Validators.required ],
      contactNumber: [ '', [ Validators.required, EvlandValidators.phoneNumber ] ],
    });
  }

  private initData(): void {
    this.callbackStore.current$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe((data: CallbackData) => {
        try {
          gtag('event', GTAG_SHOW_EVENT_NAME, {
            'value': data.type
          });
        } catch (e) {
          console.error('gtag not found');
        }

        this.data = data;

        this.cdr.markForCheck();
      });
  }
}
