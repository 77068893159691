import { DOCUMENT, isPlatformServer } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, PLATFORM_ID, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SeoService } from '@shared/services/seo.service';
import { filter, Subject, takeUntil, tap } from 'rxjs';

declare var fbq;


@Component({
  selector: 'evland-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnDestroy {
  private canonicalUrlEl: HTMLLinkElement;

  private readonly destroyed$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: string,
    private renderer: Renderer2,
    private router: Router,
    private seoService: SeoService,
  ) {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.initCanonicalUrl();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private initCanonicalUrl(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter(event => event instanceof NavigationEnd),
        tap(() => {
          try {
            fbq('track', 'PageView');
          } catch (e) {
            console.error('fbq not found');
          }
        }),
        tap(() => {
          if (this.canonicalUrlEl) {
            this.canonicalUrlEl.remove();
          }
        }),
        filter(() => window.location.href.includes('?')),
      )
      .subscribe(() => this.canonicalUrlEl = this.seoService.createCanonicalUrl(window.location.href.split('?')[0]));
  }
}

