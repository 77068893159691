import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { environment } from '@env/environment';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { InMemoryCache } from '@apollo/client/core';
import { ApolloLink } from 'apollo-link';
import { createClient } from 'graphql-ws';
import { WebSocket } from 'ws';
import { getOperationAST } from 'graphql/utilities';
export function createApollo(httpLink: HttpLink) {
  const http = httpLink.create({ uri: `${environment.apiUrl}/graphql` });
  let ws;

  try {
    ws = new GraphQLWsLink(createClient({ url: environment.wsUrl }));
  } catch {
    ws = new GraphQLWsLink(createClient({ url: environment.wsUrl, webSocketImpl: WebSocket }));
  }

  return {
    link: ApolloLink.split(
      operation => {
        const operationAST = getOperationAST(operation.query, operation.operationName);
        return !!operationAST && operationAST.operation === 'subscription';
      },
      // @ts-ignore
      ws,
      // @ts-ignore
      http,
    ),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      // useFactory(httpLink: HttpLink) {
      //   return {
      //     cache: new InMemoryCache(),
      //     link: httpLink.create({
      //       uri: `${environment.apiUrl}/graphql`,
      //     }),
      //   };
      // },
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
