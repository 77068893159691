import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

const LOCAL_STORAGE_KEY = 'auth_token';

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  private isServer: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.isServer = isPlatformServer(this.platformId);
  }

  getToken(): string {
    if (this.isServer) return null;

    return localStorage.getItem(LOCAL_STORAGE_KEY);
  }

  saveToken(token: string): void {
    if (this.isServer) return;

    localStorage.setItem(LOCAL_STORAGE_KEY, token);
  }

  destroyToken(): void {
    if (this.isServer) return;

    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }
}
