import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Optional, SkipSelf } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { startWith, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'evland-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitButtonComponent {
  @Input() color: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' = 'primary';
  @Input() isLoading = false;
  @Input() checkPristine = true;
  @Input() disabled = false;
  form: UntypedFormGroup;
  isDisabled: boolean;

  private readonly destroyed$ = new Subject<void>();

  constructor(
    @Optional() @SkipSelf() private formDirective: FormGroupDirective,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.form = this.formDirective?.form;

    this.form?.statusChanges
      .pipe(
        startWith(''),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => this.setDisabled());
  }

  ngOnChanges(): void {
    this.setDisabled();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private setDisabled(): void {
    this.isDisabled = this.disabled || (this.checkPristine && this.form?.pristine) || !this.form?.valid || this.isLoading;

    this.cdr.markForCheck();
  }
}
