import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'evland-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input() size: number = 56;
  @Input() color?: string;

  get iconStyle(): { [k: string]: number | string } {
    return {
      'width.px': this.size,
      'height.px': this.size,
    }
  }

  get strokeStyle(): { 'stroke-color': string } {
    return {
      ...(this.color && { 'stroke-color': this.color }),
    }
  }
}
