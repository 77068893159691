import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '@app/core/shared/auth.service';
import { JwtService } from '@app/core/shared/jwt.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@shared/graphql/generated';
import { ForgotPasswordModalComponent } from '@shared/modals/forgot-password-modal/forgot-password-modal.component';
import { SignInModalComponent } from '@shared/modals/sign-in-modal/sign-in-modal.component';
import { SignUpModalComponent } from '@shared/modals/sign-up-modal/sign-up-modal.component';
import { CallbackType } from '@shared/models/callback-type';
import { NavLink, NavLinkWithIcon } from '@shared/models/nav-link';
import { CallbackStoreService } from '@shared/stores/callback-store.service';
import { ComparisonStoreService } from '@shared/stores/comparison-store.service';
import { UserStoreService } from '@shared/stores/user-store.service';
import { map, Observable, take } from 'rxjs';

type AuthDismissReason = 'signIn' | 'signUp' | 'forgotPassword';

@Component({
  selector: 'evland-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @ViewChild('sidenavMenuContent') sidenavMenuContent: ElementRef;

  navLinks: NavLink[] = [
    { href: '/', label: $localize`Головна` },
    { href: '/catalog', label: $localize`Каталог` },
    { href: '/offers', label: $localize`Із пробігом` },
    { href: '/about-us', label: $localize`Про компанію` },
    { href: '/financing', label: $localize`Фінансування` },
    { href: '/contact-us', label: $localize`Контакти` },
  ];
  currentUser$: Observable<User>;
  modelsForComparison$: Observable<number>;

  constructor(
    private authService: AuthService,
    private callbackStore: CallbackStoreService,
    private comparisonStore: ComparisonStoreService,
    private cdr: ChangeDetectorRef,
    private jwtService: JwtService,
    private modalService: NgbModal,
    private userStore: UserStoreService,
  ) {
    this.currentUser$ = this.userStore.current$;
    this.modelsForComparison$ = this.comparisonStore.list$.pipe(map((ids: string[]) => ids.length));

    this.fetchCurrentUser();
  }

  onMenuToggleClick() {
    this.modalService.open(
      this.sidenavMenuContent,
      {
        ariaLabelledBy: 'modal-basic-title',
        fullscreen: true,
        windowClass: 'sidenav-menu'
      },
    );
  }

  openCallbackModal(): void {
    this.callbackStore.show({
      type: CallbackType.Consultation,
      title: $localize `Замовити консультацію`,
      actionLabel: $localize`Замовити`,
      subject: $localize`Консультація`,
    });
  }

  openSignInModal(): void {
    const modal = this.modalService.open(SignInModalComponent);

    modal
      .dismissed
      .pipe(
        take(1),
      )
      .subscribe((reason: AuthDismissReason) => this.onAuthModalDismiss(reason));
  }

  private fetchCurrentUser(): void {
    if (!this.jwtService.getToken()) return;

    this.authService.me()
      .subscribe((user: User) => {
        this.userStore.current = user;

        this.cdr.markForCheck();
      })
  }

  private onAuthModalDismiss(reason: AuthDismissReason): void {
    if (!reason) return;

    const nextModal = {
      signIn: SignInModalComponent,
      signUp: SignUpModalComponent,
      forgotPassword: ForgotPasswordModalComponent,
    };

    const modal = this.modalService.open(nextModal[reason]);

    modal
      .dismissed
      .pipe(
        take(1),
      )
      .subscribe((reason: AuthDismissReason) => this.onAuthModalDismiss(reason));
  }
}
