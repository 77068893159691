<div class="toasts-area">

  <ngb-toast
    *ngFor="let toast of toasts$ | async; trackBy: trackByFn"
    (hidden)="removeToast(toast)"
    [autohide]="true"
    [delay]="toast.delay || 5000"
    [class]="'toast-container type-' + toast.type"
  >
    {{ toast.text }}
  </ngb-toast>

</div>
