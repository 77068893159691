import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { ModalContainerModule } from '@shared/ui-components/modal-container/modal-container.module';
import { SubmitButtonModule } from '@shared/ui-components/submit-button/submit-button.module';
import { SignInModalComponent } from './sign-in-modal.component';



@NgModule({
  declarations: [
    SignInModalComponent
  ],
  imports: [
    CommonModule,
    ModalContainerModule,
    MatIconModule,
    ReactiveFormsModule,
    SubmitButtonModule,
  ]
})
export class SignInModalModule { }
