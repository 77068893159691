import { gql } from 'apollo-angular';

export const BREADCRUMB_BRAND_QUERY = gql`
  query breadcrumbBrand($id: String!) {
    brand(id: $id) {
      id
      name
    }
  }
`;

export const BREADCRUMB_MODEL_QUERY = gql`
  query breadcrumbModel($id: String!) {
    model(id: $id) {
      id
      name
      year
    }
  }
`;

export const BREADCRUMB_SERIES_QUERY = gql`
  query breadcrumbSeries($id: String!) {
    singleSeries(id: $id) {
      id
      name
    }
  }
`;

export const FILTER_BRANDS_QUERY = gql`
  query filterBrands {
    brands(take: 10000000) {
      id
      name
      series_count
      offers_count
      letter
    }
  }
`;

export const FILTER_SERIES_QUERY = gql`
  query filterSeries($brand_id: String) {
    series(brand_id: $brand_id, take: 10000000) {
      id
      name
      models_count
      offers_count
    }
  }
`;

export const FILTER_MODELS_QUERY = gql`
  query filterModels($series_id: String) {
    models(series_id: $series_id, take: 10000000) {
      id
      name
      offers_count
      year
    }
  }
`;

export const POPULAR_SERIES_QUERY = gql`
  query popularSeries {
    series(take: 12, order_by: "views", order: "DESC") {
      id
      name
      brand {
        id
        name
        logo {
          url
        }
      }
      preview {
        url
      }
      models {
        id
        price_final {
          uah
          usd
        }
        offers {
          current_price_final {
            uah
            usd
          }
        }
        additional_properties
      }
    }
  }
`;

export const STATISTICS_COUNT_QUERY = gql`
  query statisticsCount {
    countBrands
    countModels
    countOffers
  }
`;

export const SEARCH_QUERY = gql`
  query search($query: String!, $take: Int!) {
    searchBrands(query: $query, take: $take) {
      id
      name
      logo {
        url
      }
      series_count
    }
    searchSeries(query: $query, take: $take) {
      id
      name
      brand {
        id
        name
        logo {
          url
        }
      }
      preview {
        url
      }
      models {
        id
        price_final {
          uah
          usd
        }
        offers {
          current_price_final {
            uah
            usd
          }
        }
        additional_properties
      }
    }
  }
`;

export const SIGN_IN_MUTATION = gql`
  mutation signIn($input: SignInInput!) {
    signIn(input: $input) {
      access_token
    }
  }
`;

export const GET_ME_QUERY = gql`
  query getMe {
    me {
      id
      avatar {
        url
      }
      first_name
      middle_name
      last_name
      username
      date_of_birth
      email
      role
    }
  }
`;

export const LAST_TELEGRAM_SEARCHES_QUERY = gql`
  query lastTelegramSearches {
    telegramSearches(take: 100) {
      brand {
        id
        name
      }
      series {
        id
        name
      }
    }
  }
`;

export const FILTER_OFFERS_COUNT_QUERY = gql`
  query filterOffersCount($brand_id: String, $series_id: String, $model_id: String, $wheeldrive: Wheeldrive, $current_price_from: Int, $current_price_to: Int, $mileage_from: Float, $mileage_to: Float, $year_from: Int, $year_to: Int, $recharge_mileage_from: Int, $recharge_mileage_to: Int, $battery_capacity_from: Float, $battery_capacity_to: Float) {
    countOffers(brand_id: $brand_id, series_id: $series_id, model_id: $model_id, wheeldrive: $wheeldrive, current_price_from: $current_price_from, current_price_to: $current_price_to, mileage_from: $mileage_from, mileage_to: $mileage_to, year_from: $year_from, year_to: $year_to, recharge_mileage_from: $recharge_mileage_from, recharge_mileage_to: $recharge_mileage_to, battery_capacity_from: $battery_capacity_from, battery_capacity_to: $battery_capacity_to)
  }
`;


export const OFFERS_LIST_QUERY = gql`
  query offersList($brand_id: String, $series_id: String, $model_id: String, $wheeldrive: Wheeldrive, $current_price_from: Int, $current_price_to: Int, $mileage_from: Float, $mileage_to: Float, $year_from: Int, $year_to: Int, $recharge_mileage_from: Int, $recharge_mileage_to: Int, $battery_capacity_from: Float, $battery_capacity_to: Float, $take: Int!, $skip: Int!, $order_by: String, $order: String) {
    countOffers(brand_id: $brand_id, series_id: $series_id, model_id: $model_id, wheeldrive: $wheeldrive, current_price_from: $current_price_from, current_price_to: $current_price_to, mileage_from: $mileage_from, mileage_to: $mileage_to, year_from: $year_from, year_to: $year_to, recharge_mileage_from: $recharge_mileage_from, recharge_mileage_to: $recharge_mileage_to, battery_capacity_from: $battery_capacity_from, battery_capacity_to: $battery_capacity_to, take: $take, skip: $skip)
    offers(brand_id: $brand_id, series_id: $series_id, model_id: $model_id, wheeldrive: $wheeldrive, current_price_from: $current_price_from, current_price_to: $current_price_to, mileage_from: $mileage_from, mileage_to: $mileage_to, year_from: $year_from, year_to: $year_to, recharge_mileage_from: $recharge_mileage_from, recharge_mileage_to: $recharge_mileage_to, battery_capacity_from: $battery_capacity_from, battery_capacity_to: $battery_capacity_to, take: $take, skip: $skip, order_by: $order_by, order: $order) {
      id
      initial_price_final {
        uah
        usd
      }
      current_price_final {
        uah
        usd
      }
      average_price_final {
        uah
        usd
      }
      mileage
      first_registration
      additional_info
      model {
        id
        name
        year
        additional_properties
        series {
          id
          name
          brand {
            id
            name
            logo {
              url
            }
          }
        }
      }
      photos {
        id
        image {
          url
        }
      }
      created_at
      updated_at
    }
  }
`;

export const REDIRECT_OFFER_QUERY = gql`
  query redirectOffer($id: String!) {
    offer(id: $id) {
      source
      source_id
    }
  }
`;

export const HISTORY_SERIES_QUERY = gql`
  query historySeries($ids: [String!]) {
    series(ids: $ids) {
      id
      name
      brand {
        id
        name
        logo {
          url
        }
      }
      preview {
        url
      }
      models {
        id
        price_final {
          uah
          usd
        }
        offers {
          current_price_final {
            uah
            usd
          }
        }
        additional_properties
      }
    }
  }
`;

export const HISTORY_OFFERS_QUERY = gql`
  query historyOffers($ids: [String!]) {
    offers(ids: $ids) {
      id
      initial_price_final {
        uah
        usd
      }
      current_price_final {
        uah
        usd
      }
      average_price_final {
        uah
        usd
      }
      mileage
      first_registration
      additional_info
      model {
        id
        name
        year
        additional_properties
        series {
          id
          name
          brand {
            id
            name
            logo {
              url
            }
          }
        }
      }
      photos {
        id
        image {
          url
        }
      }
      created_at
      updated_at
    }
  }
`;

export const SERIES_DETAILS_QUERY = gql`
  query seriesDetails($id: String!) {
    singleSeries(id: $id, track_view: true) {
      id
      source_id
      name
      brand {
        id
        name
        logo {
          url
        }
        series_count
      }
      preview {
        url
      }
      ns_cover {
        url
      }
      appearance_detail_photos_count
      appearance_detail_photos(take: 1) {
        type
        color
        sub_color
        model_id
        image {
          url
        }
        image_thumbnail {
          url
        }
      }
      interior_decoration_detail_photos_count
      interior_decoration_detail_photos(take: 1) {
        type
        color
        sub_color
        model_id
        image {
          url
        }
        image_thumbnail {
          url
        }
      }
      space_detail_photos_count
      space_detail_photos(take: 1) {
        type
        color
        sub_color
        model_id
        image {
          url
        }
        image_thumbnail {
          url
        }
      }
      models {
        id
        name
        year
        price_final {
          uah
          usd
        }
        offers {
          current_price_final {
            uah
            usd
          }
        }
        additional_properties
        spin_views {
          id
          color
          sub_color
          image {
            url
          }
          mobile_image {
            url
          }
        }
      }
      offers_count
      offers(take: 3) {
        id
        current_price_final {
          uah
          usd
        }
        mileage
        first_registration
        additional_info
        model {
          id
          name
          year
          additional_properties
          series {
            id
            name
            brand {
              id
              name
              logo {
                url
              }
            }
          }
        }
        photos {
          id
          image {
            url
          }
        }
        updated_at
      }
      videos {
        id
        name
        url
        source
        type
        model {
          id
        }
      }
      additional_info
    }
  }
`;

export const OFFER_DETAILS_QUERY = gql`
  query offerDetails($id: String!) {
    offer(id: $id) {
      id
      initial_price_final {
        uah
        usd
      }
      current_price_final {
        uah
        usd
      }
      average_price_final {
        uah
        usd
      }
      mileage
      first_registration
      additional_info
      model {
        id
        name
        year
        additional_properties
        series {
          id
          name
          brand {
            id
            name
            logo {
              url
            }
          }
        }
      }
      photos {
        id
        image {
          url
        }
      }
      created_at
      updated_at
    }
  }
`;

export const RELATED_OFFERS_QUERY = gql`
  query relatedOffers($brand_id: String, $series_id: String, $model_id: String) {
    offers(brand_id: $brand_id, series_id: $series_id, model_id: $model_id, take: 5, skip: 0, order_by: "updated_at", order: "DESC") {
      id
      initial_price_final {
        uah
        usd
      }
      current_price_final {
        uah
        usd
      }
      average_price_final {
        uah
        usd
      }
      mileage
      first_registration
      additional_info
      model {
        id
        name
        year
        additional_properties
        series {
          id
          name
          brand {
            id
            name
            logo {
              url
            }
          }
        }
      }
      photos {
        id
        image {
          url
        }
      }
      created_at
      updated_at
    }
  }
`;

export const QUICK_BRANDS_QUERY = gql`
  query quickBrands {
    brands(take: 32, skip: 0, popular: true) {
      id
      name
      logo {
        url
      }
      series_count
    }
  }
`;

export const SERIES_DETAIL_PHOTOS_DETAILS_QUERY = gql`
  query seriesDetailPhotosDetails($id: String!) {
    singleSeries(id: $id) {
      id
      name
      brand {
        id
        name
        logo {
          url
        }
      }
      models {
        id
        name
        year
        price_final {
          uah
          usd
        }
        offers {
          current_price_final {
            uah
            usd
          }
        }
      }
      appearance_detail_photos_count
      interior_decoration_detail_photos_count
      space_detail_photos_count
    }
  }
`;

export const SERIES_APPEARANCE_PHOTOS_QUERY = gql`
  query seriesAppearancePhotos($id: String!) {
    singleSeries(id: $id) {
      appearance_detail_photos {
        id
        source_id
        color
        sub_color
        model_id
        image {
          url
        }
        image_thumbnail {
          url
        }
      }
    }
  }
`;

export const SERIES_INTERIOR_DECORATION_PHOTOS_QUERY = gql`
  query seriesInteriorDecorationPhotos($id: String!) {
    singleSeries(id: $id) {
      interior_decoration_detail_photos {
        id
        source_id
        color
        sub_color
        model_id
        image {
          url
        }
        image_thumbnail {
          url
        }
      }
    }
  }
`;

export const SERIES_SPACE_PHOTOS_QUERY = gql`
  query seriesSpacePhotos($id: String!) {
    singleSeries(id: $id) {
      space_detail_photos {
        id
        source_id
        color
        sub_color
        model_id
        image {
          url
        }
        image_thumbnail {
          url
        }
      }
    }
  }
`;

export const FILTER_SERIES_COUNT_QUERY = gql`
  query filterSeriesCount($brand_id: String, $wheeldrive: Wheeldrive, $price_from: Int, $price_to: Int, $year_from: Int, $year_to: Int, $recharge_mileage_from: Int, $recharge_mileage_to: Int, $battery_capacity_from: Float, $battery_capacity_to: Float) {
    countSeries(brand_id: $brand_id, wheeldrive: $wheeldrive, price_from: $price_from, price_to: $price_to, year_from: $year_from, year_to: $year_to, recharge_mileage_from: $recharge_mileage_from, recharge_mileage_to: $recharge_mileage_to, battery_capacity_from: $battery_capacity_from, battery_capacity_to: $battery_capacity_to)
  }
`;

export const SERIES_SPECS_DETAILS_QUERY = gql`
  query seriesSpecsDetails($seriesId: String!, $modelId: String!) {
    singleSeries(id: $seriesId) {
      id
      name
      brand {
        id
        name
        logo {
          url
        }
      }
    }
    model(id: $modelId) {
      id
      name
      year
      price_final {
        uah
        usd
      }
      photo
      offers {
        current_price_final {
          uah
          usd
        }
      }
      additional_properties
    }
  }
`;

export const SERIES_FULL_SPECS_DETAILS_QUERY = gql`
  query seriesFullSpecsDetails($seriesId: String!) {
    singleSeries(id: $seriesId) {
      id
      name
      brand {
        id
        name
        logo {
          url
        }
      }
      models {
        id
        name
        year
        price_final {
          uah
          usd
        }
        photo
        offers {
          current_price_final {
            uah
            usd
          }
        }
        additional_properties
      }
    }
  }
`;

export const SERIES_LIST_QUERY = gql`
  query seriesList($brand_id: String, $wheeldrive: Wheeldrive, $price_from: Int, $price_to: Int, $year_from: Int, $year_to: Int, $recharge_mileage_from: Int, $recharge_mileage_to: Int, $battery_capacity_from: Float, $battery_capacity_to: Float, $take: Int!, $skip: Int!, $order_by: String, $order: String) {
    countSeries(brand_id: $brand_id, wheeldrive: $wheeldrive, price_from: $price_from, price_to: $price_to, year_from: $year_from, year_to: $year_to, recharge_mileage_from: $recharge_mileage_from, recharge_mileage_to: $recharge_mileage_to, battery_capacity_from: $battery_capacity_from, battery_capacity_to: $battery_capacity_to, take: $take, skip: $skip)
    series(brand_id: $brand_id, wheeldrive: $wheeldrive, price_from: $price_from, price_to: $price_to, year_from: $year_from, year_to: $year_to, recharge_mileage_from: $recharge_mileage_from, recharge_mileage_to: $recharge_mileage_to, battery_capacity_from: $battery_capacity_from, battery_capacity_to: $battery_capacity_to, take: $take, skip: $skip, order_by: $order_by, order: $order) {
      id
      name
      brand {
        id
        name
        logo {
          url
        }
      }
      preview {
        url
      }
      models {
        id
        price_final {
          uah
          usd
        }
        offers {
          current_price_final {
            uah
            usd
          }
        }
        additional_properties
      }
    }
  }
`;

export const COMPARISON_MODELS_QUERY = gql`
  query comparisonModels($ids: [String!]) {
    models(ids: $ids) {
      id
      series {
        id
        name
        preview {
          url
        }
        brand {
          id
          name
          logo {
            url
          }
        }
      }
      name
      year
      price_final {
        uah
        usd
      }
      photo
      offers {
        current_price_final {
          uah
          usd
        }
      }
      additional_properties
    }
  }
`;

export const RELATED_SERIES_QUERY = gql`
  query relatedSeries($brand_id: String!) {
    series(brand_id: $brand_id, take: 7) {
      id
      name
      brand {
        id
        name
        logo {
          url
        }
      }
      preview {
        url
      }
      models {
        id
        price_final {
          uah
          usd
        }
        offers {
          current_price_final {
            uah
            usd
          }
        }
        additional_properties
      }
    }
  }
`;
