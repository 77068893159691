<form (ngSubmit)="onSubmit()" [formGroup]="form">

  <evland-modal-container (onClose)="onClose()" title="Зареєструйтесь або увійдіть" i18n-title>

    <div class="form-fields">

      <evland-form-field label="Ім'я користувача" i18n-label>
        <input evlandInput ngbAutofocus class="form-control" formControlName="username" />
      </evland-form-field>

      <evland-form-field label="E-Mail" i18n-label>
        <input evlandInput ngbAutofocus class="form-control" type="email" formControlName="email" />
      </evland-form-field>

      <div class="row">

        <div class="col-12 col-md-6">
          <evland-form-field label="Ім'я" i18n-label>
            <input evlandInput ngbAutofocus class="form-control" formControlName="firstName" />
          </evland-form-field>
        </div>

        <div class="col-12 col-md-6">
          <evland-form-field label="Прізвище" i18n-label>
            <input evlandInput ngbAutofocus class="form-control" formControlName="lastName" />
          </evland-form-field>
        </div>

        <div class="col-12 col-md-6">
          <evland-form-field label="По-батькові" i18n-label>
            <input evlandInput ngbAutofocus class="form-control" formControlName="middleName" />
          </evland-form-field>
        </div>

        <div class="col-12 col-md-6">
          <evland-form-field label="Дата народження" i18n-label>
            <input evlandInput ngbAutofocus class="form-control" type="date" formControlName="dateOfBirth" />
          </evland-form-field>
        </div>

      </div>

      <evland-form-field label="Пароль" i18n-label>
        <input evlandInput class="form-control" type="text" formControlName="password" />
      </evland-form-field>

      <evland-form-field label="Підтвердження пароля" i18n-label>
        <input evlandInput class="form-control" type="text" formControlName="confirmPassword" />
      </evland-form-field>

    </div>

    <evland-submit-button [isLoading]="isSubmitting" class="d-block mb-3" i18n>Зареєструватися</evland-submit-button>

    <a (click)="onSignInClick()" class="btn btn-lg btn-outline-primary d-block w-100" i18n>Увійти</a>

  </evland-modal-container>

</form>
