import { Injectable } from '@angular/core';
import { Toast } from '@shared/models/toast';
import { BaseStoreService } from '@shared/stores/base-store.service';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class ToastStoreService extends BaseStoreService<Toast>{
  show(toast: Pick<Toast, 'text' | 'type' | 'delay'>) {
    this.addToList({
      ...toast,
      id: uuid.v4(),
    });
  }

  clear() {
    this.list = [];
  }
}

