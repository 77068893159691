import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { FormFieldModule } from '@shared/ui-components/form-field/form-field.module';
import { ModalContainerModule } from '@shared/ui-components/modal-container/modal-container.module';
import { SubmitButtonModule } from '@shared/ui-components/submit-button/submit-button.module';
import { SignUpModalComponent } from './sign-up-modal.component';



@NgModule({
  declarations: [
    SignUpModalComponent
  ],
  imports: [
    CommonModule,
    ModalContainerModule,
    MatIconModule,
    ReactiveFormsModule,
    SubmitButtonModule,
    FormFieldModule,
  ]
})
export class SignUpModalModule { }
