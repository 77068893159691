import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { CallbackType } from '@shared/models/callback-type';
import { NavLinkWithIcon } from '@shared/models/nav-link';
import { CallbackStoreService } from '@shared/stores/callback-store.service';

@Component({
  selector: 'evland-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavMenuComponent {
  @Output() onClose = new EventEmitter<string>();
  navLinks: NavLinkWithIcon[] = [
    { href: '/', label: $localize `Головна`, icon: 'sax:bulk-home-1' },
    { href: '/catalog', label: $localize `Каталог`, icon: 'sax:bulk-car' },
    { href: '/offers', label: $localize `Із пробігом`, icon: 'sax:bulk-discount-shape' },
    { href: '/about-us', label: $localize `Про компанію`, icon: 'sax:bulk-book' },
    { href: '/financing', label: $localize `Фінансування`, icon: 'sax:bulk-wallet-3' },
    { href: '/contact-us', label: $localize `Контакти`, icon: 'sax:bulk-call' },
  ];

  constructor(private callbackStore: CallbackStoreService) {}

  close(): void {
    this.onClose.emit('Save click');
  }

  openCallbackModal(): void {
    this.close();
    this.callbackStore.show({
      type: CallbackType.Consultation,
      title: $localize `Замовити консультацію`,
      actionLabel: $localize`Замовити`,
      subject: $localize`Консультація`,
    });
  }
}
