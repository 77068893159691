<form (ngSubmit)="onSubmit()" [formGroup]="form">

  <evland-modal-container (onClose)="onClose()" [withFooter]="true" title="Увійдіть або зареєструйтесь" i18n-title>

    <div class="mb-3">

      <div class="mb-3">

        <label for="email" class="form-label" i18n>E-Mail</label>
        <input ngbAutofocus formControlName="email" class="form-control" id="email" />

      </div>

      <div class="mb-3">

        <label for="password" class="form-label" i18n>Пароль</label>
        <input type="password" formControlName="password" class="form-control" id="password" />

      </div>

      <div class="row justify-content-between mb-3">

        <div class="col-auto">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" formControlName="rememberMe" id="rememberMe">
            <label class="form-check-label" for="rememberMe">
              Запам'ятати мене
            </label>
          </div>
        </div>

        <div class="col-auto">
          <a (click)="onForgotPasswordClick()" class="forgot-password">Забули пароль?</a>
        </div>
      </div>

      <evland-submit-button [isLoading]="isSubmitting" class="d-block mb-3" i18n>Увійти</evland-submit-button>

      <a (click)="onSignUpClick()" class="btn btn-lg btn-outline-primary d-block w-100" i18n>Зареєструватися</a>

    </div>

    <ng-container modal-footer>

      <div class="social-login">

        <div class="title" i18n>Увійти через соц.мережі</div>

        <button class="btn btn-lg btn-light mb-3 d-flex flex-row justify-content-center align-items-center">
          <mat-icon class="icon me-2" svgIcon="social:telegram"></mat-icon>
          <span>Telegram</span>
        </button>

        <button class="btn btn-lg btn-light mb-3 d-flex flex-row justify-content-center align-items-center">
          <mat-icon class="icon me-2" svgIcon="social:google"></mat-icon>
          <span>Google</span>
        </button>

        <button class="btn btn-lg btn-light d-flex flex-row justify-content-center align-items-center">
          <mat-icon class="icon me-2" svgIcon="social:facebook"></mat-icon>
          <span>Facebook</span>
        </button>


      </div>

    </ng-container>

  </evland-modal-container>

</form>
