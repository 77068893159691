import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderModule } from '@shared/ui-components/loader/loader.module';
import { SubmitButtonComponent } from './submit-button.component';



@NgModule({
  declarations: [
    SubmitButtonComponent
  ],
  exports: [
    SubmitButtonComponent
  ],
  imports: [
    CommonModule,
    LoaderModule
  ]
})
export class SubmitButtonModule { }
