import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FormFieldComponent } from './form-field.component';
import { InputDirective } from './shared/input.directive';



@NgModule({
  declarations: [
    FormFieldComponent,
    InputDirective,
  ],
  exports: [
    FormFieldComponent,
    InputDirective
  ],
  imports: [
    CommonModule,
    MatIconModule
  ]
})
export class FormFieldModule { }
