import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/core/shared/auth.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastType } from '@shared/models/toast-type';
import { ValidationService } from '@shared/services/validation.service';
import { ToastStoreService } from '@shared/stores/toast-store.service';
import { EvlandValidators } from '@shared/utils/validators';
import { finalize } from 'rxjs';

@Component({
  selector: 'evland-sign-up-modal',
  templateUrl: './sign-up-modal.component.html',
  styleUrls: ['./sign-up-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpModalComponent {
  form: UntypedFormGroup;
  isSubmitting: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private toastStore: ToastStoreService,
    private validationService: ValidationService,
  ) {
    this.createForm();
  }

  onClose(): void {
    this.activeModal.close();
  }

  onSignInClick(): void {
    this.activeModal.dismiss('signIn');
  }

  onSubmit(): void {
    if (this.form.invalid || this.form.pristine || this.isSubmitting) return;

    this.isSubmitting = true;

    const { email, password, rememberMe } = this.form.value;

    this.authService.signIn(email, password, rememberMe)
      .pipe(
        finalize(() => {
          this.isSubmitting = false;

          this.cdr.markForCheck();
        }),
      )
      .subscribe(() => {
        this.toastStore.show({
          text: $localize`Ви успішно зареєструвалися`,
          type: ToastType.Success,
        });

        this.activeModal.close();
      }, err => {
        this.toastStore.show({
          text: $localize`Під час реєстрації сталася помилка. Можливо, щось не так із даними, які ви ввели`,
          type: ToastType.Error,
        });
      });
  }

  private createForm(): void {
    this.form = this.fb.group({
      username: [ '', Validators.required, EvlandValidators.validateUsernameAvailability(this.validationService) ],
      email: [ '', [ Validators.required, Validators.email ], EvlandValidators.validateEmailAvailability(this.validationService) ],
      firstName: [ '', Validators.required ],
      lastName: [ '', Validators.required ],
      middleName: '',
      dateOfBirth: '',
      avatar: null,
      password: [ '', Validators.required ],
      confirmPassword: [ '', Validators.required ],
    }, { validators: [ EvlandValidators.matchPassword('password', 'confirmPassword') ]});
  }
}
