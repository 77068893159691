import { User } from '@shared/graphql/generated';
import { gql } from 'apollo-angular';

export const SIGN_IN = gql`
  mutation signIn($input: SignInInput!) {
    signIn(input: $input) {
      access_token
    }
  }
`;

export const GET_ME = gql`
  query getMe {
    me {
      id
      avatar {
        url
      }
      first_name
      middle_name
      last_name
      username
      date_of_birth
      email
      role
    }
  }
`;
