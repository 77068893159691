<div [class.invalid]="invalid && touched" class="form-field">

  <label class="form-label">
    {{label}}<sup *ngIf="isRequired">*</sup>
    <div class="control-container">
      <ng-content></ng-content>
    </div>
  </label>

  <div *ngIf="invalid && touched" [@errorInOut] class="error-message">{{ message }}</div>

</div>
